<template>
    <div class="signin position-relative">
        <TitleHeader :title="title" :link="'/'" :hide_profile="true" />

        <div class="px-3">
            <div class="sign_box vstack gap-2 position-absolute top-50 start-50 translate-middle w-100 px-3">
                <router-link to="/certify" class="btn w-100 bg-secondary text-white d-flex py-3 align-items-center">
                    <span class="tit w-100">USER</span>
                </router-link>
                <router-link to="/certify" class="btn w-100 border d-flex py-3 text-body">
                    <span class="tit w-100">SMC</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '로그인 및 회원가입',
        }
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
        this.$EventBus.$emit('HideNav')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
        this.$EventBus.$emit('ShowNav')
    }
}
</script>

<style lang="scss" scoped>
    .signin{
        height: 100vh;
        min-height: 500px;
    }
</style>