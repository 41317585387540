<template>
    <div class="find_email  position-relative">
        <TitleHeader :title="title" :link="'/signin-email'" :hide_profile="true" />

        <div class="tab_find line_tab mt-2 px-3">
            <ul>
                <li><router-link to="/find-email">이메일 찾기</router-link></li>
                <li class="on"><router-link to="/find-pw">비밀번호 찾기</router-link></li>
            </ul>
        </div>
        <div class="sign_box vstack gap-2 position-absolute top-50 start-50 translate-middle w-100 px-3">
            <div class="form-group">
                <input required type="email"  class="form-control py-px-12" v-model="email" placeholder="이메일 주소 (example@gmail.com)">
            </div>
            <div @click="ChkEmail()" class="btn bg-blue py-px-12 text-white mt-3">확인</div>
        </div>

        <b-modal v-model="showModal1" size="sm" hide-header hide-footer centered>
            <div class="text-center">
                <i class="fal fa-exclamation-circle fa-3x mb-3 text-danger"></i>
                <p class="small">
                    사용자 이메일 계정이<br>
                    존재하지 않습니다.
                </p>
            </div>
        </b-modal>

        <b-modal v-model="showModal2" size="sm" hide-header hide-footer centered>
            <div class="text-center">
                <i class="fal fa-check-circle fa-3x mb-3 text-blue"></i>
                <p class="small">
                    임시 비밀번호가<br>
                    {{email}}로<br>
                    발송되었습니다.
                </p>
                <router-link class="btn btn-primary w-100 mt-4 py-px-12 small" to="/signin">로그인 하기</router-link>
            </div>
        </b-modal>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '비밀번호 찾기',

            email: '',

            // front ui
            showModal1: false,
            showModal2: false,
        }
    },
    methods:{
        ChkEmail(){
            // 이메일 없을 시
            this.showModal1 = true;

            // 이메일 있을 시
            this.showModal2 = true;
        },
    },
    
    mounted(){
        this.$EventBus.$emit('HideHeader')
        this.$EventBus.$emit('HideNav')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
        this.$EventBus.$emit('ShowNav')
    }
}
</script>

<style lang="scss" scoped>
    .find_email{
        height: 100vh;
        min-height: 500px;
    }
</style>