<template>
    <div class="find_email position-relative">
        <TitleHeader :title="title" :link="'/signin-email'" :hide_profile="true" />

        <div class="tab_find line_tab mt-2 px-3">
            <ul>
                <li class="on"><router-link to="#">이메일 찾기</router-link></li>
                <li><router-link to="/find-pw">비밀번호 찾기</router-link></li>
            </ul>
        </div>

        <div v-if="!show_result">
            <div class="sign_box vstack gap-2 position-absolute top-50 start-50 translate-middle w-100 px-3" v-if="!ko">
                <div class="form-group">
                    <input required type="text"  class="form-control py-px-12" v-model="name" placeholder="이름을 입력해주세요">
                </div>
                <div class="form-group">
                    <input required type="text" class="form-control py-px-12" v-model="number" placeholder="여권번호를 입력해주세요 (M123A4567)">
                </div>
                <div @click="ChkPw()" class="btn bg-blue py-px-12 text-white mt-3">확인</div>
            </div>
            <div class="sign_box vstack gap-2 position-absolute top-50 start-50 translate-middle w-100 px-3" v-if="ko">
                <div class="form-group">
                    <div class="input-group">
                        <input required type="number"  class="form-control py-px-12" v-model="name" placeholder="휴대전화번호 입력 (“-” 제외)">
                        <div class="certi_btn btn bg-xl-dark flex-shrink-0 small text-white text-opacity-50" @click="email_certifi_send_chk=true" v-if="!email_certifi_send_chk"><small>인증번호 전송</small></div>
                        <div class="certi_btn btn btn-success flex-shrink-0 small" v-if="email_certifi_send_chk===true">재전송</div>
                    </div>
                </div>
                <div class="small mb-2" v-if="email_certifi_send_chk">
                    <small class="text-body text-opacity-50">
                        * 3분 이내로 인증번호(6자리)를 입력해주세요.<br>
                        * 인증번호가 전송되지 않을 경우 "재전송" 버튼을 놀러주세요
                    </small>
                </div>
                <div class="form-group">
                    <div class="input-group">
                        <input required class="form-control py-px-12" type="number" placeholder="인증번호 입력" v-model="certifi_num">
                        <div class="certi_btn btn bg-xl-dark flex-shrink-0 small text-white text-opacity-50" v-if="!email_certifi_chk" @click="email_certifi_chk=true">인증확인</div>
                        <div class="certi_btn btn btn-success flex-shrink-0 small" v-if="email_certifi_chk===true">인증완료</div>
                    </div>
                </div>
                <button @click="ChkPw()" class="btn bg-blue py-px-12 text-white mt-3" :disabled="email_certifi_chk!==true">확인</button>
            </div>
        </div>

        <div class="sign_box" v-if="show_result">
            <div class="text-center py-5 my-5">
                {{user_email}}
            </div>
            <router-link to="/signin" class="btn bg-blue py-px-12 text-white mt-3 w-100">로그인 하기</router-link>
        </div>

        <b-modal v-model="showModal1" size="sm" hide-header hide-footer centered>
            <div class="text-center">
                <i class="fal fa-exclamation-circle fa-3x mb-3 text-danger"></i>
                <p class="small">
                    사용자 이메일 계정이<br>
                    존재하지 않습니다
                </p>
            </div>
        </b-modal>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '이메일 찾기',

            ko: true,

            name: '',
            number: '',

            phone: null,
            certifi_num: null,

            email_certifi_send_chk: false,
            email_certifi_chk: null,

            show_result: false,

            user_email: 'exam*****@gmail.com',

            // front ui
            showModal1: false,
        }
    },
    methods:{
        ChkPw(){
            this.show_result = true;

            // 이메일계정 없을시
            this.showModal1 = true;
        },
    },
    mounted(){
        this.$EventBus.$emit('HideHeader')
        this.$EventBus.$emit('HideNav')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
        this.$EventBus.$emit('ShowNav')
    }
}
</script>

<style lang="scss" scoped>

    .certi_btn{
        line-height: 35px;
        font-size: 14px;
        width: 90px;
        padding-left: 0;
        padding-right: 0;
    }

    .find_email{
        height: 100vh;
        min-height: 500px;
    }
</style>