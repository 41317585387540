<template>
    <div class="signin position-relative">
        <TitleHeader :title="title" :link="'/signin'" :hide_profile="true" />

        <div class="sign_box vstack gap-2 position-absolute top-50 start-50 translate-middle w-100 px-3">
            <div class="form-group">
                <input type="text"  class="form-control py-px-12" v-model="email" placeholder="이메일">
            </div>
            <div class="form-group">
                <input type="password" class="form-control py-px-12" v-model="password" placeholder="비밀번호">
            </div>
            <div @click="Login()" class="btn bg-blue py-px-12 text-white mt-3">로그인</div>
            <div class="d-flex justify-content-center align-items-center small text-body text-opacity-50 mt-3">
                <router-link to="/signup"><span class=" px-1 sm-px-2">회원가입</span></router-link>
                <span class="vr mx-1"></span>
                <router-link to="/find-email"><span class=" px-1 sm-px-2">이메일 찾기</span></router-link>
                <span class="vr mx-1"></span>
                <router-link to="/find-pw"><span class=" px-1 sm-px-2">비밀번호 찾기</span></router-link>
            </div>
        </div>

        <b-modal v-model="showModal1" footer-class="border-top-0" size="sm" hide-header centered>
            <div class="text-center">
                <h4 class="mb-3">이메일 주소를 잊으셨나요?</h4>
                <p class="small">
                    입력한 이메일 계정을 찾을 수
                    없습니다. 계정을 확인하고 다시
                    시도하세요.
                </p>
            </div>
            <template #modal-footer>
                <div class="d-flex w-100">
                    <button class="btn border w-100 me-2" @click="showModal1=false">다시 시도</button>
                    <button class="btn border w-100" @click="$router.push('/find-email')">이메일 찾기</button>
                </div>
            </template>
        </b-modal>

        <b-modal v-model="showModal2" footer-class="border-top-0" size="sm" hide-header centered>
            <div class="text-center">
                <h4 class="mb-3">비밀번호를 잊으셨나요?</h4>
                <p class="small">
                    계정에 다시 로그인할 수 있도록<br>
                    이메일로 임시 비밀번호 보내드릴 수 있습니다.
                </p>
            </div>
            <template #modal-footer>
                <div class="d-flex w-100">
                    <button class="btn border w-100 me-2" @click="showModal2=false">다시 시도</button>
                    <button class="btn border w-100" @click="SendPw()">이메일 보내기</button>
                </div>
            </template>
        </b-modal>

        <b-modal v-model="showModal3" size="sm" hide-header hide-footer centered>
            <div class="text-center">
                <i class="fal fa-check-circle fa-3x mb-3 text-blue"></i>
                <p class="small">
                    등록된 계정으로<br>
                    임시 비밀번호가<br>발송
                    되었습니다.
                </p>
            </div>
        </b-modal>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'
export default {
    components:{
        TitleHeader
    },
    data(){
        return{
            title: '이메일로 로그인',
            
            email: '',
            password: '',

            // front ui
            showModal1: false,
            showModal2: false,
            showModal3: false,
        }
    },
    methods:{
        Login(){
            // 이메일 틀렸을 경우
            this.showModal1 = true;

            // 이메일은 맞고 비번이 틀렸을 경우
            this.showModal2 = true;
        },
        SendPw(){
            this.showModal2 = false;
            this.showModal3 = true;
        }
    },
    
    mounted(){
        this.$EventBus.$emit('HideHeader')
        this.$EventBus.$emit('HideNav')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
        this.$EventBus.$emit('ShowNav')
    }
}
</script>

<style lang="scss" scoped>
    .signin{
        height: 100vh;
        min-height: 500px;
    }


</style>